export const sendBytesMetrics = async (videoId, bytesTemp) => {
    try {
        await fetch('https://api.thetavideoapi.com/metrics/offload', {
            method: 'POST',
            body: JSON.stringify({
                video_id: videoId,
                ...bytesTemp
            }),
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    } catch (e) {
        console.log('Error: ', e)
    }
}

export const fetchVideo = async () => {

    try {
        const video = await fetch('https://sheet2api.com/v1/RSBMaYwSHCof/thetatv-relaunch-stream');
        return video.json();
    } catch (e) {
        console.log('Error: ', e)
        return [{
            title: "Big Buck Bunny",
            description: "The plot follows a day in the life of Big Buck Bunny, during which time he meets three bullying rodents",
            avatar_url: "https://static.tvtropes.org/pmwiki/pub/images/BigBuckBunny.jpg",
            tva_stream_id: "stream_mwid7gvhcdw689s1pnnuxsf6i",
            tva_stream_url: "https://live5.thetavideoapi.com/hls/live/2015915/stream_sh90yf4nvnnzfry6286bxnpr2/1733524560915/master.m3u8",
            type: "live",
            show_cdn: "false",
            show_overlay: "false"
        }];
    }
}

export const fetchMetrics = async (videoId, showCdn) => {
    const cdnParam = showCdn ? '?cdn=true' : '';
    const metrics = await fetch(`https://api.thetavideoapi.com/metrics/offload${cdnParam}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return metrics.json();
}